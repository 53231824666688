import React from "react"

import LayoutScene from "../components/layout-scene"
import IndexPage from "./resume"
import SEO from "../components/seo"



import Scene from "../components/scenes/city";

// const onIdle = e => {
//
// }

const Interactive = () => (
    <div>

    <h1>What I support and believe in</h1>

        <ul>

            <li>
                <h2>Open Source</h2>
                <p>I believe in the power of open source to drive innovation and collaboration. 
                    I contribute to open source projects and believe for their importance in the tech industry.</p>
                    <p>Meanwhile understand why there still need to have an enterprise solution even around open-source stack, sometimes problems need to be solved fast and 
                        reliable in business, only possible when money involved
                    </p>
            </li>   

            <li>
                <h2>Neural Operators</h2>
                <p>Neural operators are a new class of differentiable operators that can be used to build neural networks. 
                    They are a generalization of traditional operators and can be used to build more complex models.</p>
            </li>           

            <li>
                <h2>WebGL</h2>
                <p>I am a strong believer in the power of WebGL to create interactive and immersive experiences on the web. 
                    I have used WebGL to create a variety of projects, including visualizations, games, and data visualizations.</p>

            </li>

            <li>
                <h2>Browser Based</h2>
                <p>I believe the browser should be the only app on a computer one day</p>
                <p>I am a strong believer in the power of web technologies to create interactive and immersive experiences on the web. 
                    I have used web technologies to create a variety of projects, including visualizations, games</p>
                <p>WebGPU is the future of GPU computing on the web</p>
                <p>WebAssembly is the future of low latency applications on the web</p>
            </li>


            <li>
                <h2>No conda</h2>
                <p>Conda is only messing things up, work on hardware with less abstractions possible</p>
            </li>

        </ul>


        </div>
    // <LayoutScene>

    //     <h1>What I support and believe in</h1>

    //     <ul>

    //         <li>
    //             <h2>Open Source</h2>
    //             <p>I believe in the power of open source to drive innovation and collaboration. 
    //                 I contribute to open source projects and believe for their importance in the tech industry.</p>
    //                 <p>Meanwhile understand why there still need to have an enterprise solution even around open-source stack, sometimes problems need to be solved fast and 
    //                     reliable in business, only possible when money involved
    //                 </p>
    //         </li>   

    //         <li>
    //             <h2>Neural Operators</h2>
    //             <p>Neural operators are a new class of differentiable operators that can be used to build neural networks. 
    //                 They are a generalization of traditional operators and can be used to build more complex models.</p>
    //         </li>           

    //         <li>
    //             <h2>WebGL</h2>
    //             <p>I am a strong believer in the power of WebGL to create interactive and immersive experiences on the web. 
    //                 I have used WebGL to create a variety of projects, including visualizations, games, and data visualizations.</p>

    //         </li>

    //         <li>
    //             <h2>Browser Based</h2>
    //             <p>I believe the browser should be the only app on a computer one day</p>
    //             <p>I am a strong believer in the power of web technologies to create interactive and immersive experiences on the web. 
    //                 I have used web technologies to create a variety of projects, including visualizations, games</p>
    //             <p>WebGPU is the future of GPU computing on the web</p>
    //             <p>WebAssembly is the future of low latency applications on the web</p>
    //         </li>


    //         <li>
    //             <h2>No conda</h2>
    //             <p>Conda is only messing things up, work on hardware with less abstractions possible</p>
    //         </li>

    //     </ul>

    //     {/*<IdleTimer onIdle={onIdle} timeout={600}>*/}
    //     {/*    <div>*/}
    //     {/*        <div*/}
    //     {/*            style={{*/}
    //     {/*                margin: '0 auto',*/}
    //     {/*                maxWidth: 960,*/}
    //     {/*                padding: '0px 1.0875rem 1.45rem',*/}
    //     {/*                paddingTop: 0,*/}
    //     {/*            }}*/}
    //     {/*        >*/}
    //     {/*            <p>Hahahah</p>*/}
    //     {/*        </div>*/}
    //     {/*    </div>*/}
    //     {/*</IdleTimer>*/}

    //     <SEO title="WebGL" />


    //     {/* <div style={{position:`relative`, margin: `0`, padding:`0`, border:`0`}}>
    //         <Scene />
    //     </div> */}
    // </LayoutScene>
)

export default Interactive